* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    line-height: 1.75;
}

input, button, select, textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
}

textarea {
    resize: vertical;
}

input[type='checkbox'], input[type='radio'] {
    display: none;
}

input[type='submit'], input[type='button'], label, button, select {
    cursor: pointer;
}

select::-ms-expand {
    display: none;
}

a {
    color: inherit;
    text-decoration: none;
}

ul, li {
    list-style: none;
}