.inner {
    width: 92.5%;
    max-width: 1500px;
    margin: 0 auto;

    &-medium {
        display: block;
        margin: 0 auto;
        width: 50rem;
        max-width: 92.5%;
    }

    &-small {
        display: block;
        margin: 0 auto;
        width: 25rem;
        max-width: 92.5%;
    }
}

.button {
    display: inline-block;
    padding: .5rem 1rem;

    &.is-rounded {
        border-radius: 9999px;
    }

    &.is-theme {
        background: #1FDA00;
        border-color: transparent;
        color: #FFFFFF;
        transition: background .3s;

        &:hover, .is-hovered {
            background: #0AC800;
        }

        &:active, .is-active {
            background: #00BB00;
        }

        &:focus:not(:active) {
            box-shadow: 0 0 0 0.125em rgba(72, 199, 116, 0.25);
        }
    }
}

.farmers {
    font-weight: normal;
    color: #1FDA00;
    font-family: "Black Han Sans", sans-serif;
}

.title {
    margin-top: 4rem;
    padding: 2rem 0;
    text-align: center;
    font-size: 2rem;
    font-weight: normal;
    
    @media screen and (max-width: 650px) {
        margin-top: 3rem;
    }
}

.card {
    background: #FFF;
    box-shadow: 0 2px 20px #0003;
    border-radius: .5rem;

    &-content {
        padding: 1.5rem;
    }
}

.link {
    color: #29E;
    transition: color .3s;

    &:hover {
        color: #27D;
    }

    &:active {
        color: #25C;
    }
}
