.news {
    background: #FFF;

    .list {
        padding: 2rem 0;

        a {
            display: flex;
            align-items: center;
            width: 100%;
            border-bottom: solid 1px #444;

            &:last-of-type {
                border-bottom: none;
            }

            span, div {
                display: block;
                padding: .75rem;
            }

            span {
                width: 115px;
                font-size: .9rem;
                color: #444;
            }

            div {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-left: 0;
            }
        }
    }
}