.slideshow {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;

    .backgroundImages {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        display: flex;

        img {
            width: calc(100% / 3);
            height: 100%;
            object-fit: cover;
        }

        @media screen and (max-width: 650px) {
            flex-wrap: wrap;

            img {
                width: 100%;
                height: calc(100% / 3);
            }
        }
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #222C;
        z-index: 10;
    }

    .text {
        position: relative;
        z-index: 100;
        color: #FFF;
        text-align: center;

        h2, h3 {
            display: inline-block;
            padding: 0 .5rem;
            overflow: hidden;
            font-weight: bold;
            -webkit-text-stroke: 0.5px #FFF;
        }

        h2 {
            font-size: 2.5rem;
            margin-bottom: 0.5rem;
        }

        h3 {
            font-size: 1.5rem;
        }

        .link {
            text-align: center;
            margin-top: 3rem;
            font-weight: bold;
        }

        @media screen and (max-width: 900px) {

            h2 {
                font-size: 2rem;
            }

            h3 {
                font-size: .9rem;
            }
        }
    }

    nav {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 200;

        .links ul {
            display: flex;
            justify-content: flex-end;
            list-style: none;

            li:not(:last-of-type) {
                border-right: solid 1px #FFF;
            }

            a {
                display: inline-block;
                padding: 1rem 2rem;
                color: #FFF;
                transition: background-color .3s;

                &:hover {
                    background: #FFF2
                }

                &:active {
                    background: #FFF4
                }

                @media screen and (max-width: 650px) {
                    padding: .5rem 1rem;
                    font-size: .8rem;
                }
            }
        }
    }

    .scrollDown {
        display: block;
        width: 3rem;
        height: 3rem;
        position: absolute;
        bottom: calc(38px + 5%);
        left: calc(50% - 1.5rem);
        z-index: 10;

        span {
            display: inline-block;
            width: 2rem;
            height: 2rem;
            position: absolute;
            left: .5rem;
            border-right: 2px solid #FFF;
            border-bottom: 2px solid #FFF;
            transform: rotate(45deg);
            animation: scrollSlideIn 3s ease-in-out infinite both;
            -webkit-animation: scrollSlideIn 3s ease-in-out infinite both;

            @keyframes scrollSlideIn{
                0%{
                    transform: translateY(-5px) rotate(45deg);
                    opacity:0
                }
                50%{
                    opacity:1
                }
                100%{
                    transform: translateY(5px) rotate(45deg);
                    opacity:0
                }
            }

            &:nth-of-type(1) {
                top: -10px;
                animation-delay: 0s;
            }

            &:nth-of-type(2){
                animation-delay: .5s
            }

            &:nth-of-type(3) {
                top: 10px;
                animation-delay: 1s
            }
        }
    }
}
