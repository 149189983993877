.new_infos {
    margin: 4rem 0;
    
    h4 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        text-align: center;
    }

    .new_info a {
        display: flex;
        align-items: center;
        list-style: none;

        &:not(:last-of-type) {
            border-bottom: solid 1px hsl(0, 0%, 48%);
        }

        span {
            font-size: .9rem;
            color: #4a4a4a;
        }

        div {
            width: 100%;
            overflow-x: hidden;
            text-overflow: ellipsis;
            }

        div, span {
            padding: .5rem 1rem;
            white-space: nowrap;
        }

        @media screen and (max-width: 650px) {
            flex-wrap: wrap;

            span {
                padding: .5rem 0 0 0;
            }

            div {
                padding: 0 0 .5rem 0;
            }
        }
    }

    .more {
        margin-top: 1rem;
        text-align: center;

        a {
            position: relative;
            display: inline-flex;
            align-items: center;
            padding-right: 1.25rem;

            &::after {
                content: '';
                position: absolute;
                right: 0;
                display: block;
                width: .75rem;
                height: .75rem;
                transform: rotate(-45deg);
                border-right: solid 2px hsl(204, 86%, 53%);
                border-bottom: solid 2px hsl(204, 86%, 53%);
                transition: border-color .3s;
            }

            &:hover::after {
                border-color: hsl(217, 71%, 53%);
            }
        }
    }
}
