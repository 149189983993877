.members {
    padding: 2rem 0;
    background: #FFF;

    ul {
        display: flex;
        list-style: none;

        @media screen and (max-width: 650px) {
            flex-wrap: wrap;
        }
    }
}