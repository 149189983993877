.motto {
    display: flex;
    align-items: center;
    padding-bottom: 5rem;

    &:first-of-type {
        padding-top: 5rem;
    }

    $width: 32vw;
    $max-width: 1500px * 0.32;
    $height: 24vw;
    $max-height: 1500px * 0.24;
    $left: 2rem;
    $top: 2rem;

    .left {
        position: relative;
        width: 0;
        max-width: calc(#{$max-width} + #{$left});
        height: calc(#{$height} + #{$top});
        max-height: calc(#{$max-height} + #{$top});
        flex-shrink: 0;
        transition: width 2s;
        overflow: hidden;

        &::before {
            content: '';
            display: block;
            width: $width;
            max-width: $max-width;
            height: $height;
            max-height: $max-height;
            position: absolute;
            top: $top;
            left: $left;
            z-index: 50;
            background: #0008;

            @media screen and (max-width: 900px) {
                top: calc(#{$top} / 2);
                left: calc(#{$left} / 2);
            }
        }

        img {
            position: absolute;
            width: $width;
            max-width: $max-width;
            height: $height;
            max-height: $max-height;
            object-fit: cover;

            &:first-of-type {
                top: 0;
                left: 0;
                z-index: 100;
            }

            &:last-of-type {
                top: $top;
                left: $left;

                @media screen and (max-width: 900px) {
                    top: calc(#{$top} / 2);
                    left: calc(#{$left} / 2);
                }
            }
        }
    }

    &.view .left {
        width: calc(#{$width} + #{$left});
    }

    .right {
        width: calc(100% - #{$width} - #{$left});
        padding-left: $left;
        margin-left: auto;

        @media screen and (max-width: 900px) {
            padding-left: calc(#{$left} / 2);
        }

        h3 {
            position: relative;
            display: flex;
            align-items: center;
            font-size: 1.5rem;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                display: block;
                width: 0;
                height: 1px;
                background: #000;
                transition: width 2s ease-in-out;
            }

            & > span {
                position: relative;
                display: inline-block;
                padding-right: 1rem;
                background: #FFF;
                z-index: 100;
            }
        }
    }

    &.view h3::before {
        width: 100%
    }

    @media screen and (max-width: 650px) {
        flex-wrap: wrap;
        justify-content: start;

        $width: 80vw;
        $height: 45vw;
        $left: 1.5rem;
        $top: 1.5rem;

        .left {
            width: 0;
            max-width: calc(#{$max-width} + #{$left});
            height: calc(#{$height} + #{$top});
            max-height: calc(#{$max-height} + #{$top});
            overflow: hidden;
            transition: width 2s;
            margin-left: calc(50% - (#{$width} + #{$left}) / 2);

            &::before {
                width: $width;
                max-width: $max-width;
                height: $height;
                max-height: $max-height;
                top: $top;
                left: $left;
            }

            img {
                width: $width;
                max-width: $max-width;
                height: $height;
                max-height: $max-height;

                &:last-of-type {
                    top: $top;
                    left: $left;
                }
            }
        }

        &.view .left {
            width: calc(#{$width} + #{$left});
        }

        .right {
            width: 100%;
            padding-left: 0;
            margin-top: 2.5rem;
        }
    }
}
