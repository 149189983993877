.post {
    list-style: none;
    display: block;
    width: calc(100% / 6);
    height: calc(92.5vw * ( 1 / 6 ));
    overflow: hidden;

    @media screen and (min-width: 651px) and (max-width: 1200px) {
        width: 25%;
        height: calc(92.5vw * 0.25);

        &:nth-of-type(n+9) {
            display: none;
        }
    }

    @media screen and (min-width: 451px) and (max-width: 650px) {
        width: calc(100% / 3);
        height: calc(92.5vw * ( 1 / 3 ));

        &:nth-of-type(n+10) {
            display: none;
        }
    }

    @media screen and (max-width: 450px) {
        width: calc(100% / 2);
        height: calc(92.5vw * ( 1 / 2 ));

        &:nth-of-type(n+5) {
            display: none;
        }
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: relative;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 100%;
            left: 0;
            opacity: 0;
            background: #0008;
            color: #FFF;
            font-size: 1.25rem;
            transform: scale(0.5);
            transition: opacity .3s ease-in-out, top .3s ease-in-out, transform .3s ease-in-out;
        }

        &:hover span {
            top: 0;
            opacity: 1;
            transform: scale(1);
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}