.footer {
    background: #333;
    color: #FFF;
    font-size: .8rem;
    margin-top: 4rem;

    a {
        transition: opacity .3s;

        &:hover {
            opacity: .8;
        }

        &:active {
            opacity: .6;
        }
    }

    .content {
        display: flex;
        align-items: stretch;
        padding: .75rem 0;

        .left, .right {
            width: 50%;
            padding: 1.5rem;
        }

        .left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            h1, h2 {
                width: 100%;
            }

            h1, h2, span {
                line-height: 1.25;
            }

            h1 {
                font-size: 1.75rem;

                span {
                    color: #FFF!important;
                }
            }

            h2 {
                font-size: 1rem;
            }
        }

        .right {
            display: flex;
            align-items: center;
            font-size: .9rem;
            border-left: solid 1px #FFF;

            div {
                width: 100%;

                .site_links {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    width: 100%;

                    li {
                        width: calc(100% / 3);
                        padding: .25rem .5rem;

                        @media screen and (max-width: 1200px) {
                            width: 50%;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 800px) {
            flex-wrap: wrap;
            padding: 0;

            .left, .right {
                width: 100%;
                border: none;
            }

            .left {
                border-bottom: solid 1px #FFF;

                h1 {
                    font-size: 1.5rem;
                }
            }

            .right li {
                width: auto!important;
            }
        }
    }

    .other_links {
        padding-top: .25rem;
        display: flex;
        flex-wrap: wrap;
        border-top: solid 1px #FFF;

        li {
            padding: .25rem .5rem;

            span {
                color: #FFF!important;
            }
        }
    }
}