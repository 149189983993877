.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 4rem;
    z-index: 9999;
    background: #222;
    transition: box-shadow .3s;

    a {
        transition: opacity .3s;

        &:hover {
            opacity: .8;
        }

        &:active {
            opacity: .6;
        }
    }

    nav {
        height: 100%;
        display: flex;
        align-items: center;

        h1 {
            margin-right: auto;

            a {
                display: block;
                line-height: 1;

                img {
                    display: block;
                    width: auto;
                    height: 1.75rem;
                }
            }
        }

        ul {
            display: flex;
            align-items: center;
            list-style: none;
            transition: opacity .3s;

            a {
                display: block;
                padding: 0 1rem;
                color: #FFF;
                font-weight: bold;
            }

            li:last-of-type a {
                padding-right: 0;
            }
        }

        label {
            display: none;
        }

        @media screen and (max-width: 800px) {

            h1 a img {
                height: 1.5rem;
            }

            label {
                display: block;
                background: #FFF;
                color: #222;
                font-weight: bold;
                cursor: pointer;
                transition: opacity .3s;
                order: 1000;
                padding: .125rem .25rem;

                &:hover {
                    opacity: .9;
                }

                &:active {
                    opacity: .8;
                }
            }

            ul {
                display: block;
                order: 500;
                opacity: 1;
                position: fixed;
                top: 4rem;
                left: 100vw;
                width: 100%;
                height: 100vh;
                background: #FFFE;
                transition: left ease-in-out 500ms;

                li, a {
                    padding: 0;
                }

                li {
                    width: 92.5%;
                    max-width: 1500px;
                    margin: 0 auto;
                    text-align: center;

                    a {
                        padding: 1rem 0;
                        color: #222;
                        transition: background .3s;

                        &:hover {
                            opacity: 1;
                            background: #2222;
                        }

                        &:active {
                            opacity: 1;
                            background: #2223;
                        }
                    }
                }
            }

            input[type="checkbox"]:checked ~ ul {
                left: 0;
            }
        }
    }

    &.shadow {
        box-shadow: 0 2px 10px #0003;
    }
}
