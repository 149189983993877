.view-animation {
    display: inline-block;
    position: relative;
    
    span {
        opacity: 0;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -120%;
        display: block;
        width: 110%;
        height: 100%;
        background: white;
    }

    &.view::before {
        animation: view-animation-box 1s ease-in-out forwards;
    }

    &.view span {
        animation: view-animation-text 1s ease-in-out forwards;
    }

    @keyframes view-animation-box {
        0% {
            left: -120%;
        }
        100% {
            left: 110%;
        }
    }

    @keyframes view-animation-text {
        49% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
    }
}

.appear {
    transform: translateY(10vh);
    opacity: 0;
    transition: opacity 2s, transform 2s;
    will-change: opacity, transform;
    
    &.fast {
        transition: opacity 1s, transform 1s;
    }

    &.view {
        opacity: 1;
        transform: translateY(0);
    }
}
