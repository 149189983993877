.member {
    position: relative;
    display: block;
    width: 40%;
    padding: 1rem;
    
    &:nth-of-type(odd) {
        margin-left: 10%;
    }
    
    &:nth-of-type(even) {
        transition-delay: 500ms;
    }

    @media screen and (max-width: 650px) {
        flex-wrap: wrap;
        width: 80%;
        margin: 0 auto!important;

        @media screen and (max-width: 450px) {
            width: 100%;
        }
    }

    img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: .5rem;
    }

    div {
        padding-top: .5em;
        
        span {
            font-size: .8rem;
        }

        h3 {
            font-size: 1.5rem;
        }
        
        p {
            font-size: .9rem;
            padding-top: .5rem;
        }
    }
}
