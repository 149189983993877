.about {
    background: #FFF;
    
    ul {
        list-style: none;
        padding: 2rem 0;
        
        li {
            display: flex;
            
            span, p {
                padding: .75rem;
            }
            
            span {
                display: inline-block;
                width: 14rem;
                flex-shrink: 0;
                font-weight: bold;
            }
            
            @media screen and (max-width: 900px) {
                flex-wrap: wrap;
                
                span {
                    width: 100%;
                    padding-bottom: 0.125rem;
                }
                
                p {
                    width: 100%;
                    padding-top: 0.125rem;
                }
            }
        }
    }
}
